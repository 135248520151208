var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rescuetime-wrapper" },
    [
      _vm._m(0),
      _vm._l(_vm.categories, function (category, indx) {
        return _c("div", { key: indx, staticClass: "category-row" }, [
          _c("p", { staticClass: "category-rank" }, [
            _vm._v(_vm._s(category.rank)),
          ]),
          _c("p", { staticClass: "category-name" }, [_vm._v(_vm._s(indx))]),
          _c("p", { staticClass: "category-time" }, [
            _vm._v(_vm._s(category.minutes) + " min"),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-row" }, [
      _c("p", { staticClass: "title-rank" }, [_vm._v("Rank")]),
      _c("p", { staticClass: "title-name" }, [_vm._v("Category")]),
      _c("p", { staticClass: "title-time" }, [_vm._v("Time spent")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }